import { Inject, Injectable } from '@angular/core';
import { User } from '@Libs/model';
import { UserService } from '@WebUi/dashboard/services/user.service';
import { environment } from '@WebUi/env';
import { ROLLBAR_CONFIG } from '@WebUi/helpers/injection-tokens';
import Rollbar from 'rollbar';

@Injectable({
  providedIn: 'root',
})
export class RollbarService extends Rollbar {

  constructor(
    @Inject(ROLLBAR_CONFIG) private config: Rollbar.Configuration,
    private userService: UserService,
  ) {
    super(config);
  }

  init(): void {
    this.userService.user$
      .subscribe((user: User) => {
        this.rollbar.configure({
          payload: {
            person: {
              id: user.id,
              email: user.email,
              username: user.fullName,
            },
          },
        });
      });
  }

  public override log(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    if (!environment.logging.rollbar) {
      return {
        uuid: '',
      };
    }

    return super.log(args);
  }

  public override debug(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    if (!environment.logging.rollbar) {
      return {
        uuid: '',
      };
    }

    return super.debug(args);
  }

  public override info(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    if (!environment.logging.rollbar) {
      return {
        uuid: '',
      };
    }

    return super.info(args);
  }

  public override warn(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    if (!environment.logging.rollbar) {
      return {
        uuid: '',
      };
    }

    return super.warn(args);
  }

  public override warning(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    if (!environment.logging.rollbar) {
      return {
        uuid: '',
      };
    }

    return super.warning(args);
  }

  public override error(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    if (!environment.logging.rollbar) {
      return {
        uuid: '',
      };
    }

    return super.error(args);
  }

  public override critical(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    if (!environment.logging.rollbar) {
      return {
        uuid: '',
      };
    }

    return super.critical(args);
  }

}
