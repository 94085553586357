import { Injectable } from '@angular/core';
import { CompanyConnection, ID, UserCompany } from '@Libs/model';
import { Observable } from 'rxjs';

@Injectable()
export abstract class ActiveCompanyService {

  abstract readonly activeCompanyId$: Observable<ID | null>;
  abstract readonly activeCompany$: Observable<UserCompany | null>;
  abstract readonly activeCompanyConnections$: Observable<CompanyConnection[] | null>;
  abstract readonly activeCompanyHasConnections$: Observable<boolean | null>;
  abstract readonly activeCompanyAccountingSystemConnection$: Observable<CompanyConnection | null>;

}
