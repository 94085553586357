import {
  AccountingSystem,
  ExternalSystemTypeFeature,
  ExternalSystemTypeFeatureBookkepingMode,
  ID,
  PaymentProvider,
  SalesChannel,
  UnimicroPlatformCompany,
  UnimicroPlatformCompanyDescriptor,
} from '@Libs/model';

export enum OnboardingInitStep {
  INITIAL = 1,
  // ON
}

export enum OnboardingOption {
  AUTOMATIC_ACCOUNTING_FOR_ESTORE = 1,
  AUTOMATIC_ACCOUNTING_FOR_PAYMENT_PROVIDER = 2,
}

export const ONBOARDING_OPTIONS: OnboardingOption[] = [
  OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE,
  OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_PAYMENT_PROVIDER,
];

export function isSupportedOnboardingOption(option: any): option is OnboardingOption {
  return ONBOARDING_OPTIONS.includes(option);
}

export type SupportedOnboardingFeature = ExternalSystemTypeFeatureBookkepingMode;

export type SupportedOnboardingFeatureForSalesChannel = ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING;

export type SupportedOnboardingFeatureForPaymentProvider = ExternalSystemTypeFeatureBookkepingMode;

export const SUPPORTED_ONBOARDING_FEATURES: SupportedOnboardingFeature[] = [
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING,
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING,
  ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING,
  ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING,
];

export const SUPPORTED_ONBOARDING_FEATURES_FOR_SALES_CHANNEL: SupportedOnboardingFeatureForSalesChannel[] = [
  ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING,
];

export const SUPPORTED_ONBOARDING_FEATURES_FOR_PAYMENT_PROVIDER: SupportedOnboardingFeatureForPaymentProvider[] = [
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING,
  ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING,
  ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING,
  ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING,
];

export function isSupportedOnboardingFeature(feature: any): feature is SupportedOnboardingFeature {
  return SUPPORTED_ONBOARDING_FEATURES.includes(feature);
}

export function isSupportedOnboardingFeatureForSalesChannel(feature: any): feature is SupportedOnboardingFeatureForSalesChannel {
  return SUPPORTED_ONBOARDING_FEATURES_FOR_SALES_CHANNEL.includes(feature);
}

export function isSupportedOnboardingFeatureForPaymentProvider(feature: any): feature is SupportedOnboardingFeatureForPaymentProvider {
  return SUPPORTED_ONBOARDING_FEATURES_FOR_PAYMENT_PROVIDER.includes(feature);
}

export type OnboardingInput = {
  option: OnboardingOption | null;
  feature: SupportedOnboardingFeature | null;
  agreementId: number | null;
  accountingSystem: AccountingSystem | null;
  salesChannel: SupportedOnboardingSalesChannel | null;
  shopifyToken: OnboardingShopifyToken | null;
  hasEstore: boolean | null;
  paymentProvider: SupportedOnboardingPaymentProvider | null;
};

export type OnboardingFlowState = OnboardingSalesChannelFlowState | OnboardingPaymentProviderFlowState;

export type OnboardingFlowStateRequiredData = OnboardingSalesChannelFlowStateRequiredData | OnboardingPaymentProviderFlowStateRequiredData;

export type OnboardingSalesChannelFlowState = {
  option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE;
  feature: SupportedOnboardingFeatureForSalesChannel;
  companyId: ID | null;
  agreementId: number;
  accountingSystem: AccountingSystem;
  salesChannel: SupportedOnboardingSalesChannel;
  shopifyToken: OnboardingShopifyToken | null;
  hasEstore: boolean;
  unimicroPlatformCompanies: UnimicroPlatformCompanyDescriptor[] | null;
  unimicroPlatformCompany: UnimicroPlatformCompany | null;
  unimicroPlatformCompanyKey: string | null;
};

export type OnboardingSalesChannelFlowStateRequiredData = Pick<OnboardingSalesChannelFlowState, 'option' | 'feature' | 'agreementId' | 'accountingSystem' | 'salesChannel' | 'hasEstore' | 'shopifyToken'>;

export type OnboardingPaymentProviderFlowState = {
  option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_PAYMENT_PROVIDER;
  feature: SupportedOnboardingFeatureForPaymentProvider;
  companyId: ID | null;
  agreementId: number;
  accountingSystem: AccountingSystem;
  paymentProvider: SupportedOnboardingPaymentProvider;
  unimicroPlatformCompanies: UnimicroPlatformCompanyDescriptor[] | null;
  unimicroPlatformCompany: UnimicroPlatformCompany | null;
  unimicroPlatformCompanyKey: string | null;
};

export type OnboardingPaymentProviderFlowStateRequiredData = Pick<OnboardingPaymentProviderFlowState, 'option' | 'feature' | 'agreementId' | 'accountingSystem' | 'paymentProvider'>;

export const ONBOARDING_SALES_CHANNEL_FLOW_STATE_DEFAULTS: Pick<OnboardingSalesChannelFlowState, Exclude<keyof OnboardingSalesChannelFlowState, keyof OnboardingSalesChannelFlowStateRequiredData> | 'option'> = {
  option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE,
  companyId: null,
  unimicroPlatformCompanies: null,
  unimicroPlatformCompany: null,
  unimicroPlatformCompanyKey: null,
};

export const ONBOARDING_PAYMENT_PROVIDER_FLOW_STATE_DEFAULTS: Pick<OnboardingPaymentProviderFlowState, Exclude<keyof OnboardingPaymentProviderFlowState, keyof OnboardingPaymentProviderFlowStateRequiredData> | 'option'> = {
  option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_PAYMENT_PROVIDER,
  companyId: null,
  unimicroPlatformCompanies: null,
  unimicroPlatformCompany: null,
  unimicroPlatformCompanyKey: null,
};

export type SupportedOnboardingSalesChannel = SalesChannel.UNIMICRO_NETTBUTIKK | SalesChannel.SHOPIFY | SalesChannel.MOOCOMMERCE;

export const SUPPORTED_ONBOARDING_SALES_CHANNEL: SupportedOnboardingSalesChannel[] = [
  SalesChannel.UNIMICRO_NETTBUTIKK,
  SalesChannel.SHOPIFY,
  SalesChannel.MOOCOMMERCE,
];

export function isSupportedOnboardingSalesChannel(salesChannel: any): salesChannel is SupportedOnboardingSalesChannel {
  return SUPPORTED_ONBOARDING_SALES_CHANNEL.includes(salesChannel);
}

export type SupportedOnboardingPaymentProvider = PaymentProvider;

export const SUPPORTED_ONBOARDING_PAYMENT_PROVIDER: SupportedOnboardingPaymentProvider[] = [
  PaymentProvider.VIPPS,
  PaymentProvider.KLARNA,
  PaymentProvider.STRIPE,
  PaymentProvider.NETS,
  PaymentProvider.PAYPAL,
  PaymentProvider.CLEARHAUS,
  PaymentProvider.SVEA,
  PaymentProvider.QLIRO,
];

export function isSupportedOnboardingPaymentProvider(option: any): option is SupportedOnboardingPaymentProvider {
  return SUPPORTED_ONBOARDING_PAYMENT_PROVIDER.includes(option);
}

export type OnboardingShopifyToken = {
  connectionToken: string;
  shop: string;
};

export type OIDCPrompt = 'none' | 'login' | 'consent' | 'select_account';
