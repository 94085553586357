export interface UnimicroPlatformInvoiceSettings {
  shippingSku: string | null;
  defaultProductSku: string | null;
  invoiceDueDatePeriodDays: number | null;
  autoRefundOnCreditNote: boolean; // false by default
  createNonPrepaidInvoiceAsDraft: boolean; // false by default
  customerMatchingFields: number | null;
}

export enum CustomerMatchingField {
  EMAIL = 1,
  PHONE = 2,
  CUSTOMER_NAME = 4,
  COMPANY_NAME = 8,
  COMPANY_NUMBER = 16,
  CUSTOMER_NUMBER = 32,
}

export const CUSTOMER_MATCHING_FIELDS: CustomerMatchingField[] = [
  CustomerMatchingField.EMAIL,
  CustomerMatchingField.PHONE,
  CustomerMatchingField.CUSTOMER_NUMBER,
  CustomerMatchingField.CUSTOMER_NAME,
  CustomerMatchingField.COMPANY_NUMBER,
  CustomerMatchingField.COMPANY_NAME,
];
