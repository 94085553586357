import {
  Company,
  CompanyConnection,
  CompanyToCreate,
  CompanyUser,
  ExternalSystem,
  ID,
  UserCompany,
} from '@Libs/model';
import { TransactionsWidgetSettings } from '@WebUi/dashboard/models/dashboard.model';

export class ToggleDashboardSidebar {
  static readonly actionName = 'ToggleDashboardSidebar';
  static readonly type = '[Dashboard] Toggle dashboard sidebar';
}

export class ToggleDashboardDemoData {
  static readonly actionName = 'ToggleDashboardDemoData';
  static readonly type = '[Dashboard] Toggle dashboard demo data';
}

export class StoreTransactionsWidgetSettings {
  static readonly actionName = 'StoreTransactionsWidgetSettings';
  static readonly type = '[Dashboard] Store transactions widget settings';

  constructor(public transactionsWidgetSettings: TransactionsWidgetSettings) { }
}

export class GetExternalSystemTypeKinds {
  static readonly actionName = 'GetExternalSystemTypeKinds';
  static readonly type = '[Dashboard] Get external system type kinds';
}

export class GetUser {
  static readonly actionName = 'GetUser';
  static readonly type = '[Dashboard] Get user';
}

export class GetUserCompanies {
  static readonly actionName = 'GetUserCompanies';
  static readonly type = '[Dashboard] Get user companies';
}

export class SetActiveCompany {
  static readonly actionName = 'SetActiveCompany';
  static readonly type = '[Dashboard] Set active company';

  constructor(public companyId: ID) { }
}

export class SetCompanyOwner {
  static readonly actionName = 'SetCompanyOwner';
  static readonly type = '[Dashboard] Set company owner';

  constructor(public companyUser: CompanyUser) { }
}

export class DeleteCompany {
  static readonly actionName = 'DeleteCompany';
  static readonly type = '[Dashboard] Delete company';

  constructor(public companyId: ID) { }
}

export class CreateCompany {
  static readonly actionName = 'CreateCompany';
  static readonly type = '[Dashboard] Create company';

  constructor(public company: CompanyToCreate) { }
}

export class UpdateCompany {
  static readonly actionName = 'UpdateCompany';
  static readonly type = '[Dashboard] Update company';

  constructor(public company: Company) { }
}

export class RevokeCompanyAccess {
  static readonly actionName = 'RevokeCompanyAccess';
  static readonly type = '[Dashboard] Revoke company access';

  constructor(public companyId: ID, public userId: ID) { }
}

export class CompleteCompanyConnection {
  static readonly actionName = 'CompleteCompanyConnection';
  static readonly type = '[Dashboard] Complete company connection';

  constructor(
    public companyId: ID,
    public connectionToken: string,
    public externalSystemId: string,
  ) { }
}

export class CreateCompanyConnection {
  static readonly actionName = 'CreateCompanyConnection';
  static readonly type = '[Dashboard] Create company connection';

  constructor(
    public companyId: ID,
    public externalSystemId: string,
    public externalSystemTypeId: ExternalSystem,
    public connectionData: unknown,
    public token: string | null = null,
  ) { }
}

/*
  Service action
  It serves to propogate results from CreateCompany action

  TODO get rid of it in future when we migrate from graphql to REST
*/
export class CreateCompanyFeedback {
  static readonly actionName = 'CreateCompanyFeedback';
  static readonly type = '[Dashboard] Create company feedback';

  constructor(public company: UserCompany) { }
}

/*
  Service action
  It serves to propogate results from CreateCompanyConnection/CompleteCompanyConnection actions

  TODO get rid of it in future when we migrate from graphql to REST
*/
export class CompanyConnectionCreatedFeedback {
  static readonly actionName = 'CompanyConnectionCreatedFeedback';
  static readonly type = '[Dashboard] Company connection created feedback';

  constructor(
    public companyId: ID,
    public companyConnection: CompanyConnection,
  ) { }
}

export class StoreCompanyAccountingSystemConnection {
  static readonly actionName = 'StoreCompanyAccountingSystemConnection';
  static readonly type = '[Dashboard] Store company accounting system connection';

  constructor(
    public companyId: ID,
    public connection: CompanyConnection,
  ) { }
}

export class UpdateCompanyConnection {
  static readonly actionName = 'UpdateCompanyConnection';
  static readonly type = '[Dashboard] Update company connection';

  constructor(
    public companyId: ID,
    public connectionId: ID,
    public connectionData: unknown,
  ) { }
}

export class DeleteCompanyConnection {
  static readonly actionName = 'DeleteCompanyConnection';
  static readonly type = '[Dashboard] Delete company connection';

  constructor(
    public companyId: ID,
    public companyConnectionId: ID,
  ) { }
}
