import {
  CompanyConnection,
  DateRangeFilterOptions,
  PaymentProvider,
  PayoutSummaryBookkeepingReport,
  PayoutSummaryBookkeepingReportSortKey,
  PayoutSummaryBookkeepingReportStatus,
  PayoutTransactionReportSortKey,
  PayoutTransactionReportStatus,
  SortDirection,
} from '@Libs/model';
import { TableColumnName, TableColumnProps, TableContext, TableSchema, TableSortOptions } from '@umw-admin/ui-kit';

export type PayoutTransactionReportsTableColumnName = TableColumnName<
  'index' |
  'transactionId' |
  'provider' |
  'transactionType' |
  'transactionDateUtc' |
  'transactionAmount' |
  'bookkeepingDetails' |
  'statusType' |
  'details'>;

export type PayoutTransactionReportsTableColumnProps = TableColumnProps<PayoutTransactionReportsTableColumnName>;

export type PayoutTransactionReportsTableSchema = TableSchema<PayoutTransactionReportsTableColumnName>;

export type PayoutTransactionReportsTableContext = TableContext<PayoutTransactionReportsTableColumnName>;

export type PayoutTransactionReportsFilterOptions = {
  connectionId: CompanyConnection['externalSystemId'][] | null;
  paymentProvider: PaymentProvider[] | null;
  searchKey: string | null;
  status: PayoutTransactionReportStatus[] | null;
} & DateRangeFilterOptions;

export type PayoutTransactionReportsSortOptions = TableSortOptions<PayoutTransactionReportsTableColumnName>;

export type PayoutTransactionReportsOptions = PayoutTransactionReportsTableContext & {
  filter: PayoutTransactionReportsFilterOptions;
};

export const PAYOUT_TRANSACTION_REPORTS_TABLE_COLUMN_NAME_TO_SORT_KEY_MAP: Record<PayoutTransactionReportsTableColumnName, PayoutTransactionReportSortKey | null> = {
  'index': null,
  'transactionId': null,
  'provider': PayoutTransactionReportSortKey.PAYMENT_PROVIDER,
  'transactionType': null,
  'transactionDateUtc': PayoutTransactionReportSortKey.TRANSACTION_DATE_UTC,
  'transactionAmount': null,
  'bookkeepingDetails': null,
  'statusType': null,
  'details': null,
};

export const DEFAULT_PAYOUT_TRANSACTION_REPORTS_TABLE_CONTEXT: PayoutTransactionReportsTableContext = {
  schema: [
    {
      name: 'index',
      width: 65,
      isVisible: true,
    },
    {
      name: 'transactionId',
      width: 225,
      isVisible: true,
    },
    {
      name: 'provider',
      width: 100,
      isVisible: true,
    },
    {
      name: 'transactionType',
      width: 80,
      isVisible: true,
    },
    {
      name: 'transactionDateUtc',
      width: 110,
      isVisible: true,
    },
    {
      name: 'transactionAmount',
      width: 130,
      isVisible: true,
    },
    {
      name: 'bookkeepingDetails',
      width: 205,
      isVisible: true,
    },
    {
      name: 'statusType',
      width: 140,
      isVisible: true,
    },
    {
      name: 'details',
      width: 95,
      isVisible: true,
    },
  ],
  sort: {
    direction: SortDirection.DESC,
    key: 'transactionDateUtc',
  },
};

export enum ExtraPayoutSummaryBookkeepingReportStatus {
  DRAFT_BOOKKEEPT = 4,
  DRAFT_BOOKKEEPT_WITH_WARNING = 5,
}

export type ExtendedPayoutSummaryBookkeepingReportStatus = PayoutSummaryBookkeepingReportStatus | ExtraPayoutSummaryBookkeepingReportStatus;

export type ExtendedPayoutSummaryBookkeepingReport = Omit<PayoutSummaryBookkeepingReport, 'status'> & {
  status: ExtendedPayoutSummaryBookkeepingReportStatus;
};

export type PayoutSummaryBookkeepingReportsTableColumnName = TableColumnName<
  'index' |
  'payoutDateTimeStamp' |
  'sourceId' |
  'journalEntryNr' |
  'incomeAmountExclVat' |
  'feeAmount' |
  'vatAmount' |
  'status' |
  'details'>;

export type PayoutSummaryBookkeepingReportsTableColumnProps = TableColumnProps<PayoutSummaryBookkeepingReportsTableColumnName>;

export type PayoutSummaryBookkeepingReportsTableSchema = TableSchema<PayoutSummaryBookkeepingReportsTableColumnName>;

export type PayoutSummaryBookkeepingReportsTableContext = TableContext<PayoutSummaryBookkeepingReportsTableColumnName>;

export type PayoutSummaryBookkeepingReportsFilterOptions = {
  searchKey: string | null;
  status: PayoutSummaryBookkeepingReportStatus[] | null;
} & DateRangeFilterOptions;

export type PayoutSummaryBookkeepingReportsSortOptions = TableSortOptions<PayoutSummaryBookkeepingReportsTableColumnName>;

export type PayoutSummaryBookkeepingReportsOptions = PayoutSummaryBookkeepingReportsTableContext & {
  filter: PayoutSummaryBookkeepingReportsFilterOptions;
};

export const PAYOUT_SUMMARY_BOOKKEEPING_REPORTS_TABLE_COLUMN_NAME_TO_SORT_KEY_MAP: Record<PayoutSummaryBookkeepingReportsTableColumnName, PayoutSummaryBookkeepingReportSortKey | null> = {
  'index': null,
  'payoutDateTimeStamp': PayoutSummaryBookkeepingReportSortKey.PAYOUT_DATE_UTC,
  'sourceId': PayoutSummaryBookkeepingReportSortKey.SOURCE_ID,
  'journalEntryNr': PayoutSummaryBookkeepingReportSortKey.JOURNAL_ENTRY_NUMBER,
  'incomeAmountExclVat': PayoutSummaryBookkeepingReportSortKey.INCOME_AMOUNT,
  'feeAmount': PayoutSummaryBookkeepingReportSortKey.FEE_AMOUNT,
  'vatAmount': PayoutSummaryBookkeepingReportSortKey.VAT_AMOUNT,
  'status': PayoutSummaryBookkeepingReportSortKey.STATUS,
  'details': null,
};

export const DEFAULT_PAYOUT_SUMMARY_BOOKKEEPING_REPORTS_TABLE_CONTEXT: PayoutSummaryBookkeepingReportsTableContext = {
  schema: [
    {
      name: 'index',
      width: 65,
      isVisible: true,
    },
    {
      name: 'payoutDateTimeStamp',
      width: 125,
      isVisible: true,
    },
    {
      name: 'sourceId',
      width: 120,
      isVisible: true,
    },
    {
      name: 'journalEntryNr',
      width: 120,
      isVisible: true,
    },
    {
      name: 'incomeAmountExclVat',
      width: 135,
      isVisible: true,
    },
    {
      name: 'feeAmount',
      width: 115,
      isVisible: true,
    },
    {
      name: 'vatAmount',
      width: 115,
      isVisible: true,
    },
    {
      name: 'status',
      width: 135,
      isVisible: true,
    },
    {
      name: 'details',
      width: 95,
      isVisible: true,
    },
  ],
  sort: {
    direction: SortDirection.DESC,
    key: 'payoutDateTimeStamp',
  },
};
