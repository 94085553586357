import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import {
  CompanyConnectionCreatedFeedback,
  CompleteCompanyConnection,
  CreateCompanyConnection,
  DeleteCompany,
  DeleteCompanyConnection,
  GetExternalSystemTypeKinds,
  GetUser,
  GetUserCompanies,
  CreateCompany,
  CreateCompanyFeedback,
  RevokeCompanyAccess,
  SetActiveCompany,
  StoreCompanyAccountingSystemConnection,
  StoreTransactionsWidgetSettings,
  ToggleDashboardDemoData,
  ToggleDashboardSidebar,
  UpdateCompany,
  UpdateCompanyConnection,
  SetCompanyOwner,
} from '@WebUi/dashboard/store/dashboard.actions';
import { DashboardStateModel, DASHBOARD_STATE_DEFAULTS } from '@WebUi/dashboard/models/dashboard-state.model';
import {
  CompleteCompanyConnectionResponse,
  CompleteCompanyConnectionVariables,
  COMPLETE_COMPANY_CONNECTION,
  CreateCompanyResponse,
  CreateCompanyVariables,
  CREATE_COMPANY,
  DeleteCompanyConnectionResponse,
  DeleteCompanyConnectionVariables,
  DeleteCompanyResponse,
  DeleteCompanyVariables,
  DELETE_COMPANY,
  DELETE_COMPANY_CONNECTION,
  GetUserCompaniesResponse,
  GetUserResponse,
  GET_USER,
  GET_USER_COMPANIES,
  RevokeCompanyAccessResponse,
  RevokeCompanyAccessVariables,
  REVOKE_COMPANY_ACCESS,
  UpdateCompanyConnectionResponse,
  UpdateCompanyConnectionVariables,
  UpdateCompanyResponse,
  UpdateCompanyVariables,
  UPDATE_COMPANY,
  UPDATE_COMPANY_CONNECTION,
  ID,
  ExternalSystemTypeKind,
  ExternalSystemType,
  isExternalSystemTypeActive,
  isAccountingSystemConnectionPredicate,
  CreateCompanyConnectionVariables,
  CreateCompanyConnectionResponse,
  CREATE_COMPANY_CONNECTION,
  ExternalSystemKind,
  ExternalSystem,
} from '@Libs/model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { append, compose, iif, patch, removeItem, updateItem } from '@ngxs/store/operators';
import {
  CompanyConnection,
  CompanyConnectionState,
  CompanyUser,
  CompanyUserRole,
  User,
  UserCompany,
} from '@Libs/model';
import { DashboardSettings, DashboardSidebarToggleState } from '@WebUi/dashboard/models/dashboard.model';
import {
  CompanyConnectionBrokenSignal,
  CompanyConnectionCreatedSignal,
  CompanyConnectionDeletedSignal,
  CompanyConnectionUpdatedSignal,
} from '@WebUi/notifications/store/notifications.actions';
import { CompanyManagerApiService } from '@WebUi/dashboard/services/company-manager-api.service';

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: DASHBOARD_STATE_DEFAULTS,
})
@Injectable()
export class DashboardState {

  @Selector([DashboardState])
  static dashboardSettings(state: DashboardStateModel): DashboardSettings {
    return state.dashboardSettings;
  }

  // Selector needed as a transition selector for DashboardState.externalSystemTypeKinds
  // to prevent expensive selector recalculations
  @Selector([DashboardState])
  static unfilteredExternalSystemTypeKinds(state: DashboardStateModel): ExternalSystemTypeKind[] {
    return state.externalSystemTypeKinds;
  }

  @Selector([DashboardState.unfilteredExternalSystemTypeKinds])
  static externalSystemTypeKinds(unfilteredExternalSystemTypeKinds: ExternalSystemTypeKind[]): ExternalSystemTypeKind[] {
    return unfilteredExternalSystemTypeKinds.map((externalSystemTypeKind: ExternalSystemTypeKind) => {
      return {
        ...externalSystemTypeKind,
        externalSystemTypes: externalSystemTypeKind.externalSystemTypes.filter(isExternalSystemTypeActive),
      };
    });
  }

  @Selector([DashboardState.externalSystemTypeKinds])
  static externalSystemTypes(externalSystemTypeKinds: ExternalSystemTypeKind[]): ExternalSystemType[] {
    return externalSystemTypeKinds.reduce((acc: ExternalSystemType[], externalSystemTypeKind: ExternalSystemTypeKind) => {
      return acc.concat(externalSystemTypeKind.externalSystemTypes);
    }, []);
  }

  @Selector([DashboardState.externalSystemTypeKinds])
  static externalSystemTypeKindById(externalSystemTypeKinds: ExternalSystemTypeKind[]): (arg: ExternalSystemKind) => ExternalSystemTypeKind | null {
    return (externalSystemKind: ExternalSystemKind) => {
      return externalSystemTypeKinds.find((externalSystemTypeKind: ExternalSystemTypeKind) => externalSystemTypeKind.id === externalSystemKind) ?? null;
    };
  }

  @Selector([DashboardState.externalSystemTypes])
  static externalSystemTypeById(externalSystemTypes: ExternalSystemType[]): (arg: ExternalSystem) => ExternalSystemType | null {
    return (externalSystem: ExternalSystem) => {
      return externalSystemTypes.find((externalSystemType: ExternalSystemType) => externalSystemType.id === externalSystem) ?? null;
    };
  }

  @Selector([DashboardState.externalSystemTypes])
  static externalSystemTypesByKinds(externalSystemTypes: ExternalSystemType[]): (arg: ExternalSystemKind[]) => ExternalSystemType[] {
    return (externalSystemKinds: ExternalSystemKind[]) => {
      return externalSystemTypes.filter((externalSystemType: ExternalSystemType) => externalSystemKinds.includes(externalSystemType.typeKindId));
    };
  }

  @Selector([DashboardState.externalSystemTypes])
  static externalSystemTypesByKind(externalSystemTypes: ExternalSystemType[]): (arg: ExternalSystemKind) => ExternalSystemType[] {
    return (externalSystemKind: ExternalSystemKind) => {
      return externalSystemTypes.filter((externalSystemType: ExternalSystemType) => externalSystemType.typeKindId === externalSystemKind);
    };
  }

  @Selector([DashboardState])
  static user(state: DashboardStateModel): User {
    return state.user;
  }

  @Selector([DashboardState.user])
  static userInitials(user: User): string {
    const fullName: string = user.fullName
      .split(' ')
      .reduce((accumulator: string, chunk: string) => accumulator.concat(chunk ? chunk[0].toUpperCase() : ''), '');

    return fullName ? fullName : '??';
  }

  @Selector([DashboardState.user])
  static userCompanies(user: User): UserCompany[] {
    return user.companies;
  }

  @Selector([DashboardState.userCompanies])
  static hasUserCompanies(companies: UserCompany[]): boolean {
    return companies.length > 0;
  }

  @Selector([DashboardState])
  static activeUserCompanyId(state: DashboardStateModel): ID | null {
    return state.activeUserCompanyId;
  }

  @Selector([DashboardState.userCompanies, DashboardState.activeUserCompanyId])
  static activeUserCompany(userCompanies: UserCompany[], activeUserCompanyId: ID | null): UserCompany | null {
    if (!activeUserCompanyId) {
      return null;
    }

    return userCompanies.find((company: UserCompany) => company.id === activeUserCompanyId) ?? null;
  }

  @Selector([DashboardState.activeUserCompany])
  static activeUserCompanyConnections(activeUserCompany: UserCompany | null): CompanyConnection[] | null {
    return activeUserCompany?.connections ?? null;
  }

  @Selector([DashboardState.activeUserCompanyConnections])
  static activeUserCompanyHasConnections(activeUserCompanyConnections: CompanyConnection[] | null): boolean | null {
    return activeUserCompanyConnections ? activeUserCompanyConnections.length > 0 : null;
  }

  @Selector([
    DashboardState.activeUserCompanyConnections,
    DashboardState.externalSystemTypes,
  ])
  static activeUserCompanyAccountingSystemConnection(
    connections: CompanyConnection[] | null,
    externalSystemTypes: ExternalSystemType[],
  ): CompanyConnection | null {
    return connections?.find(isAccountingSystemConnectionPredicate(externalSystemTypes)) ?? null;
  }

  @Selector([DashboardState.user, DashboardState.activeUserCompany])
  static isUserOwnerOfActiveCompany(user: User, activeUserCompany: UserCompany | null): boolean | null {
    if (!activeUserCompany) {
      return false;
    }

    const companyUser: CompanyUser | undefined = activeUserCompany.users.find((companyUser: CompanyUser) => companyUser.userId === user.id);

    if (!companyUser) {
      return false;
    }

    return companyUser.role === CompanyUserRole.OWNER;
  }

  constructor(
    private apollo: Apollo,
    private companyManagerApiService: CompanyManagerApiService,
  ) { }

  @Action(ToggleDashboardSidebar)
  toggleDashboardSidebar(ctx: StateContext<DashboardStateModel>): void {
    const sidebarToggleState: DashboardSidebarToggleState = ctx.getState().dashboardSettings.sidebarToggleState;

    ctx.setState(
      patch({
        dashboardSettings: patch({
          sidebarToggleState: sidebarToggleState === DashboardSidebarToggleState.EXPANDED ? DashboardSidebarToggleState.COLLAPSED : DashboardSidebarToggleState.EXPANDED,
        }),
      }),
    );
  }

  @Action(ToggleDashboardDemoData)
  toggleDashboardDemoData(ctx: StateContext<DashboardStateModel>): void {
    const showDemoData: boolean = ctx.getState().dashboardSettings.showDemoData;

    ctx.setState(
      patch({
        dashboardSettings: patch({
          showDemoData: !showDemoData,
        }),
      }),
    );
  }

  @Action(StoreTransactionsWidgetSettings)
  storeTransactionsWidgetSettings(ctx: StateContext<DashboardStateModel>, payload: StoreTransactionsWidgetSettings): void {
    ctx.setState(
      patch({
        dashboardSettings: patch({
          widgets: patch({
            transactions: payload.transactionsWidgetSettings,
          }),
        }),
      }),
    );
  }

  @Action(GetExternalSystemTypeKinds)
  getExternalSystemTypeKinds(ctx: StateContext<DashboardStateModel>): Observable<ExternalSystemTypeKind[] | null> {
    return this.companyManagerApiService.fetchExternalSystemTypeKind()
      .pipe(
        tap((result: ExternalSystemTypeKind[] | null) => {
          if (!result) {
            return;
          }

          result.sort((a: ExternalSystemTypeKind, b: ExternalSystemTypeKind) => a.displayOrder - b.displayOrder);

          result.forEach((externalSystemTypeKind: ExternalSystemTypeKind) => {
            externalSystemTypeKind.externalSystemTypes.sort((a: ExternalSystemType, b: ExternalSystemType) => a.displayOrder - b.displayOrder);
          });

          ctx.patchState({
            externalSystemTypeKinds: result,
          });
        }),
      );
  }

  @Action(GetUser)
  getUser(ctx: StateContext<DashboardStateModel>): Observable<ApolloQueryResult<GetUserResponse>> {
    return this.apollo.use('companyManager').query<GetUserResponse>({
      query: GET_USER,
    })
      .pipe(
        filter((result: ApolloQueryResult<GetUserResponse>) => !!result && !result.loading && !result.partial && !result.error && !result.errors && !!result.data),
        tap((result: ApolloQueryResult<GetUserResponse>) => {
          ctx.patchState({
            user: result.data.user,
          });

          const state: DashboardStateModel = ctx.getState();

          const activeUserCompanyId: ID | null = state.activeUserCompanyId;
          const isActiveCompanyExists: boolean = state.user.companies.some((company: UserCompany) => company.id === activeUserCompanyId);

          if (!isActiveCompanyExists) {
            if (result.data.user.companies[0]) {
              ctx.patchState({
                activeUserCompanyId: result.data.user.companies[0].id,
              });
            } else {
              ctx.patchState({
                activeUserCompanyId: DASHBOARD_STATE_DEFAULTS.activeUserCompanyId,
              });
            }
          }
        }),
      );
  }

  @Action(GetUserCompanies)
  getUserCompanies(ctx: StateContext<DashboardStateModel>): Observable<ApolloQueryResult<GetUserCompaniesResponse>> {
    return this.apollo.use('companyManager').query<GetUserCompaniesResponse>({
      query: GET_USER_COMPANIES,
    })
      .pipe(
        filter((result: ApolloQueryResult<GetUserCompaniesResponse>) => !!result && !result.loading && !result.partial && !result.error && !result.errors && !!result.data),
        tap((result: ApolloQueryResult<GetUserCompaniesResponse>) => {
          ctx.setState(
            patch({
              user: patch({
                companies: result.data.user.companies,
              }),
            }),
          );

          const state: DashboardStateModel = ctx.getState();

          const activeUserCompanyId: ID | null = state.activeUserCompanyId;
          const isActiveCompanyExists: boolean = state.user.companies.some((company: UserCompany) => company.id === activeUserCompanyId);

          if (!isActiveCompanyExists) {
            if (result.data.user.companies[0]) {
              ctx.patchState({
                activeUserCompanyId: result.data.user.companies[0].id,
              });
            } else {
              ctx.patchState({
                activeUserCompanyId: DASHBOARD_STATE_DEFAULTS.activeUserCompanyId,
              });
            }
          }
        }),
      );
  }

  @Action(SetActiveCompany)
  setActiveCompany(ctx: StateContext<DashboardStateModel>, payload: SetActiveCompany): void {
    ctx.patchState({
      activeUserCompanyId: payload.companyId,
    });
  }

  @Action(SetCompanyOwner)
  setCompanyOwner(ctx: StateContext<DashboardStateModel>, payload: SetCompanyOwner): void {
    ctx.setState(
      patch({
        user: patch({
          companies: updateItem(
            (company: Readonly<UserCompany>) => company.id === payload.companyUser.companyId,
            patch({
              users: compose(
                updateItem(
                  (user: Readonly<CompanyUser>) => user.role === CompanyUserRole.OWNER,
                  patch({
                    role: CompanyUserRole.REGULAR,
                  }),
                ),
                updateItem(
                  (user: Readonly<CompanyUser>) => user.userId === payload.companyUser.userId,
                  patch({
                    role: CompanyUserRole.OWNER,
                  }),
                ),
              ),
            }),
          ),
        }),
      }),
    );
  }

  @Action(DeleteCompany)
  deleteCompany(ctx: StateContext<DashboardStateModel>, payload: DeleteCompany): Observable<FetchResult<DeleteCompanyResponse>> {
    return this.apollo.use('companyManager').mutate<DeleteCompanyResponse, DeleteCompanyVariables>({
      mutation: DELETE_COMPANY,
      variables: {
        companyId: payload.companyId,
      },
    })
      .pipe(
        tap((result: FetchResult<DeleteCompanyResponse>) => {
          if (!result.data) {
            return;
          }

          if (!result.data.deleteCompany) {
            // Company has not been deleted on the server
            return;
          }

          ctx.setState(
            patch({
              user: patch({
                companies: removeItem((userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId),
              }),
            }),
          );

          const newActiveCompany: UserCompany | undefined = ctx.getState().user.companies[0];

          if (newActiveCompany) {
            ctx.patchState({
              activeUserCompanyId: newActiveCompany.id,
            });
          } else {
            ctx.patchState({
              activeUserCompanyId: DASHBOARD_STATE_DEFAULTS.activeUserCompanyId,
            });
          }
        }),
      );
  }

  @Action(CreateCompany)
  createCompany(ctx: StateContext<DashboardStateModel>, payload: CreateCompany): Observable<void> {
    return this.apollo.use('companyManager').mutate<CreateCompanyResponse, CreateCompanyVariables>({
      mutation: CREATE_COMPANY,
      variables: {
        company: {
          companyNumber: payload.company.companyNumber,
          name: payload.company.name,
          billingAddress: {
            address1: payload.company.billingAddress.address1,
            postalCode: payload.company.billingAddress.postalCode,
            postalArea: payload.company.billingAddress.postalArea,
            countryCode: payload.company.billingAddress.countryCode,
          },
          contactPerson: {
            email: payload.company.contactPerson.email,
            name: payload.company.contactPerson.name,
            phone: payload.company.contactPerson.phone,
          },
          invoiceRecipientEmail: payload.company.invoiceRecipientEmail,
        },
      },
    })
      .pipe(
        switchMap((result: FetchResult<CreateCompanyResponse>) => {
          if (!result.data) {
            return throwError(() => new Error('CreateCompanyError'));
          }

          ctx.setState(
            patch({
              user: patch({
                companies: iif(
                  (userCompanies: Readonly<UserCompany[]>) => userCompanies.every((company: Readonly<UserCompany>) => company.id !== result.data!.createCompany.id),
                  append([result.data.createCompany]),
                ),
              }),
            }),
          );

          return ctx.dispatch([
            new SetActiveCompany(result.data.createCompany.id),
            new CreateCompanyFeedback(result.data.createCompany),
          ]);
        }),
      );
  }

  @Action(UpdateCompany)
  updateCompany(ctx: StateContext<DashboardStateModel>, payload: UpdateCompany): Observable<FetchResult<UpdateCompanyResponse>> {
    return this.apollo.use('companyManager').mutate<UpdateCompanyResponse, UpdateCompanyVariables>({
      mutation: UPDATE_COMPANY,
      variables: {
        companyId: payload.company.id,
        companyName: payload.company.name,
        companyNumber: payload.company.companyNumber,
        billingAddress: {
          address1: payload.company.billingAddress.address1,
          countryCode: payload.company.billingAddress.countryCode,
          postalArea: payload.company.billingAddress.postalArea,
          postalCode: payload.company.billingAddress.postalCode,
        },
        contactPerson: {
          email: payload.company.contactPerson.email,
          name: payload.company.contactPerson.name,
          phone: payload.company.contactPerson.phone,
        },
        invoiceRecipientEmail: payload.company.invoiceRecipientEmail,
      },
    })
      .pipe(
        tap((result: FetchResult<UpdateCompanyResponse>) => {
          if (!result.data) {
            return;
          }

          ctx.setState(
            patch({
              user: patch({
                companies: updateItem(
                  (userCompany: Readonly<UserCompany>) => userCompany.id === payload.company.id,
                  patch({
                    name: result.data.updateCompany.name,
                    companyNumber: result.data.updateCompany.companyNumber,
                    billingAddress: result.data.updateCompany.billingAddress,
                    contactPerson: result.data.updateCompany.contactPerson,
                    invoiceRecipientEmail: result.data.updateCompany.invoiceRecipientEmail,
                  }),
                ),
              }),
            }),
          );
        }),
      );
  }

  @Action(RevokeCompanyAccess)
  revokeCompanyAccess(ctx: StateContext<DashboardStateModel>, payload: RevokeCompanyAccess): Observable<FetchResult<RevokeCompanyAccessResponse>> {
    return this.apollo.use('companyManager').mutate<RevokeCompanyAccessResponse, RevokeCompanyAccessVariables>({
      mutation: REVOKE_COMPANY_ACCESS,
      variables: {
        companyId: payload.companyId,
        userId: payload.userId,
      },
    })
      .pipe(
        tap((result: FetchResult<RevokeCompanyAccessResponse>) => {
          if (!result.data) {
            return;
          }

          if (!result.data.revokeCompanyAccess) {
            // Company access has not been revoked on the server
            return;
          }

          // TODO - why do we have this condition?
          if (ctx.getState().user.id === payload.userId) {
            // User can not revoke own acces
            return;
          }

          ctx.setState(
            patch({
              user: patch({
                companies: updateItem(
                  (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
                  patch({
                    users: removeItem((companyUser: Readonly<CompanyUser>) => companyUser.userId === payload.userId && companyUser.companyId === payload.companyId),
                  }),
                ),
              }),
            }),
          );
        }),
      );
  }

  @Action(CompleteCompanyConnection)
  completeCompanyConnection(ctx: StateContext<DashboardStateModel>, payload: CompleteCompanyConnection): Observable<void> {
    return this.apollo.use('companyManager').mutate<CompleteCompanyConnectionResponse, CompleteCompanyConnectionVariables>({
      mutation: COMPLETE_COMPANY_CONNECTION,
      variables: {
        companyId: payload.companyId,
        connectionToken: payload.connectionToken,
        externalSystemId: payload.externalSystemId,
      },
    })
      .pipe(
        switchMap((result: FetchResult<CompleteCompanyConnectionResponse>) => {
          if (!result.data?.completeCompanyConnection) {
            return throwError(() => new Error('CompleteCompanyConnectionError'));
          }

          ctx.setState(
            patch({
              user: patch({
                companies: updateItem(
                  (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
                  patch({
                    connections: iif(
                      (connections: Readonly<CompanyConnection[]>) => connections.every((connection: Readonly<CompanyConnection>) => connection.id !== result.data!.completeCompanyConnection.id),
                      append([result.data.completeCompanyConnection]),
                    ),
                  }),
                ),
              }),
            }),
          );

          return ctx.dispatch([
            new CompanyConnectionCreatedFeedback(payload.companyId, result.data.completeCompanyConnection),
          ]);
        }),
      );
  }

  @Action(CreateCompanyConnection)
  createCompanyConnection(ctx: StateContext<DashboardStateModel>, payload: CreateCompanyConnection): Observable<void> {
    return this.apollo.use('companyManager').mutate<CreateCompanyConnectionResponse, CreateCompanyConnectionVariables>({
      mutation: CREATE_COMPANY_CONNECTION,
      variables: {
        companyId: payload.companyId,
        externalSystemId: payload.externalSystemId,
        externalSystemTypeId: payload.externalSystemTypeId,
        connectionData: JSON.stringify(payload.connectionData),
        token: payload.token,
      },
    })
      .pipe(
        switchMap((result: FetchResult<CreateCompanyConnectionResponse>) => {
          if (!result.data?.createCompanyConnection) {
            return throwError(() => new Error('CreateCompanyConnectionError'));
          }

          ctx.setState(
            patch({
              user: patch({
                companies: updateItem(
                  (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
                  patch({
                    connections: iif(
                      (connections: Readonly<CompanyConnection[]>) => connections.every((connection: Readonly<CompanyConnection>) => connection.id !== result.data!.createCompanyConnection.id),
                      append([result.data.createCompanyConnection]),
                    ),
                  }),
                ),
              }),
            }),
          );

          return ctx.dispatch([
            new CompanyConnectionCreatedFeedback(payload.companyId, result.data.createCompanyConnection),
          ]);
        }),
      );
  }

  @Action(StoreCompanyAccountingSystemConnection)
  storeCompanyAccountingSystemConnection(
    ctx: StateContext<DashboardStateModel>,
    payload: StoreCompanyAccountingSystemConnection,
  ): void {
    ctx.setState(
      patch({
        user: patch({
          companies: updateItem(
            (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
            patch({
              connections: iif(
                (connections: Readonly<CompanyConnection[]>) => connections.some((connection: Readonly<CompanyConnection>) => connection.id === payload.connection.id),
                updateItem(
                  (connection: Readonly<CompanyConnection>) => connection.id === payload.connection.id,
                  payload.connection,
                ),
                append([payload.connection]),
              ),
            }),
          ),
        }),
      }),
    );
  }

  @Action(UpdateCompanyConnection)
  updateCompanyConnection(
    ctx: StateContext<DashboardStateModel>,
    payload: UpdateCompanyConnection,
  ): Observable<FetchResult<UpdateCompanyConnectionResponse>> {
    return this.apollo.use('companyManager').mutate<UpdateCompanyConnectionResponse, UpdateCompanyConnectionVariables>({
      mutation: UPDATE_COMPANY_CONNECTION,
      variables: {
        companyId: payload.companyId,
        connectionId: payload.connectionId,
        connectionData: JSON.stringify(payload.connectionData),
      },
    })
      .pipe(
        tap((result: FetchResult<UpdateCompanyConnectionResponse>) => {
          if (!result.data?.updateCompanyConnection) {
            return;
          }

          ctx.setState(
            patch({
              user: patch({
                companies: updateItem(
                  (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
                  patch({
                    connections: updateItem(
                      (connection: Readonly<CompanyConnection>) => connection.id === result.data!.updateCompanyConnection.id,
                      patch({
                        connectionData: result.data.updateCompanyConnection.connectionData,
                      }),
                    ),
                  }),
                ),
              }),
            }),
          );
        }),
      );
  }

  @Action(DeleteCompanyConnection)
  deleteCompanyConnection(
    ctx: StateContext<DashboardStateModel>,
    payload: DeleteCompanyConnection,
  ): Observable<FetchResult<DeleteCompanyConnectionResponse>> {
    return this.apollo.use('companyManager').mutate<DeleteCompanyConnectionResponse, DeleteCompanyConnectionVariables>({
      mutation: DELETE_COMPANY_CONNECTION,
      variables: {
        connectionId: payload.companyConnectionId,
      },
    })
      .pipe(
        tap((result: FetchResult<DeleteCompanyConnectionResponse>) => {
          if (!result.data?.deleteCompanyConnection) {
            return;
          }

          ctx.setState(
            patch({
              user: patch({
                companies: updateItem(
                  (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
                  patch({
                    connections: updateItem(
                      (connection: Readonly<CompanyConnection>) => connection.id === payload.companyConnectionId,
                      patch({
                        state: CompanyConnectionState.PENDING_DELETE,
                      }),
                    ),
                  }),
                ),
              }),
            }),
          );
        }),
      );
  }

  @Action(CompanyConnectionCreatedSignal)
  companyConnectionCreatedSignal(ctx: StateContext<DashboardStateModel>, payload: CompanyConnectionCreatedSignal): void {
    ctx.setState(
      patch({
        user: patch({
          companies: updateItem(
            (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
            patch({
              connections: iif(
                (connections: Readonly<CompanyConnection[]>) => connections.every((connection: Readonly<CompanyConnection>) => connection.id !== payload.companyConnection.id),
                append([payload.companyConnection]),
              ),
            }),
          ),
        }),
      }),
    );
  }

  @Action(CompanyConnectionUpdatedSignal)
  companyConnectionUpdatedSignal(ctx: StateContext<DashboardStateModel>, payload: CompanyConnectionUpdatedSignal): void {
    ctx.setState(
      patch({
        user: patch({
          companies: updateItem(
            (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
            patch({
              connections: updateItem(
                (companyConnection: Readonly<CompanyConnection>) => companyConnection?.id === payload.companyConnection.id,
                payload.companyConnection,
              ),
            }),
          ),
        }),
      }),
    );
  }

  @Action(CompanyConnectionDeletedSignal)
  companyConnectionDeletedSignal(ctx: StateContext<DashboardStateModel>, payload: CompanyConnectionDeletedSignal): void {
    ctx.setState(
      patch({
        user: patch({
          companies: updateItem(
            (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
            patch({
              connections: removeItem((companyConnection: Readonly<CompanyConnection>) => companyConnection.id === payload.companyConnectionId),
            }),
          ),
        }),
      }),
    );
  }

  @Action(CompanyConnectionBrokenSignal)
  companyConnectionBrokenSignal(ctx: StateContext<DashboardStateModel>, payload: CompanyConnectionBrokenSignal): void {
    ctx.setState(
      patch({
        user: patch({
          companies: updateItem(
            (userCompany: Readonly<UserCompany>) => userCompany.id === payload.companyId,
            patch({
              connections: updateItem(
                (companyConnection: Readonly<CompanyConnection>) => companyConnection.externalSystemId === payload.companyConnectionBrokeMessage.externalSystemId && companyConnection.externalSystemTypeId === payload.companyConnectionBrokeMessage.externalSystemType,
                patch({
                  state: CompanyConnectionState.BROKEN,
                  stateMessage: payload.companyConnectionBrokeMessage.problemDescription,
                }),
              ),
            }),
          ),
        }),
      }),
    );
  }

}
