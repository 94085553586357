import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BridgeDatePipe } from '@WebUi/shared/pipes/bridge-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { TranslatedContentDirective } from '@WebUi/shared/directives/translated-content.directive';
import { TranslatedElementDirective } from '@WebUi/shared/directives/translated-element.directive';
import { TRANSLATE_MODULE_CONFIG } from '@WebUi/app/configs/translate-module.config';
import { BridgeDateTimePipe } from '@WebUi/shared/pipes/bridge-datetime.pipe';
import { TooltipDirective } from '@WebUi/shared/directives/tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { BridgeAmountPipe } from '@WebUi/shared/pipes/bridge-amount.pipe';
import { PromptModalComponent } from '@WebUi/shared/components/prompt-modal/prompt-modal.component';
import { IconInputComponent } from '@WebUi/shared/components/icon-input/icon-input.component';
import { ToastSimpleContentComponent } from '@WebUi/shared/components/toast-templates/simple-content/simple-content.component';
import { ToastExpandableContentComponent } from '@WebUi/shared/components/toast-templates/expandable-content/expandable-content.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ShowHidePasswordDirective } from '@WebUi/shared/directives/show-hide-password.directive';
import { BridgeCurrencyPipe } from '@WebUi/shared/pipes/bridge-currency.pipe';
import { LetDirective } from '@WebUi/shared/directives/let.directive';
import { FormLabelComponent } from '@WebUi/shared/standalone-components/form-label/form-label.component';
import { FormControlComponent } from '@WebUi/shared/standalone-components/form-control/form-control.component';
import { FormDescriptionComponent } from '@WebUi/shared/standalone-components/form-description/form-description.component';
import { FormLineComponent } from '@WebUi/shared/standalone-components/form-line/form-line.component';
import { BackdropComponent } from '@WebUi/shared/standalone-components/backdrop/backdrop.component';
import { HeroiconComponent } from '@umw-admin/ui-kit';

@NgModule({
  declarations: [
    PromptModalComponent,
    BridgeDatePipe,
    BridgeDateTimePipe,
    BridgeAmountPipe,
    BridgeCurrencyPipe,
    TranslatedContentDirective,
    TranslatedElementDirective,
    TooltipDirective,
    ShowHidePasswordDirective,
    IconInputComponent,
    ToastSimpleContentComponent,
    ToastExpandableContentComponent,
  ],
  imports: [
    CommonModule,
    LetDirective,
    HeroiconComponent,
    RouterModule,
    OverlayModule,
    CdkAccordionModule,
    TranslateModule.forChild(TRANSLATE_MODULE_CONFIG),
    FormLabelComponent,
    FormControlComponent,
    FormDescriptionComponent,
    FormLineComponent,
    BackdropComponent,
  ],
  exports: [
    CommonModule,
    LetDirective,
    PromptModalComponent,
    BridgeDatePipe,
    BridgeDateTimePipe,
    BridgeAmountPipe,
    BridgeCurrencyPipe,
    TranslatedContentDirective,
    TranslatedElementDirective,
    TooltipDirective,
    ShowHidePasswordDirective,
    ToastSimpleContentComponent,
    ToastExpandableContentComponent,
    FormLabelComponent,
    FormControlComponent,
    FormDescriptionComponent,
    FormLineComponent,
    BackdropComponent,
  ],
})
export class SharedModule { }
