import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CompanyConnection,
  ExternalSystemType,
  GetUserCompanyAsSuResponse,
  GetUserCompanyAsSuResponseVariables,
  GET_USER_COMPANY_AS_SU,
  ID,
  isAccountingSystemConnectionPredicate,
  UserCompany,
  GetAllCompaniesAsSuResponse,
  GET_ALL_COMPANIES_AS_SU,
  CompanyInfo,
  CompanyUser,
  CompanyUserRole,
} from '@Libs/model';
import { SuStateModel, SU_STATE_DEFAULTS } from '@WebUi/su/models/su-state.model';
import {
  GetObservedCompany,
  ResetObservedCompanies,
  AddObservedCompany,
  DeleteObservedCompany,
  UpdateObservedCompany,
  SetActiveSuCompany,
  StoreTvSettings,
  GetAllCompanies,
} from '@WebUi/su/store/su.actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { append, compose, iif, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { TvSettings } from '@WebUi/su/models/su.model';
import { CompanyConnectionCreatedFeedback, SetActiveCompany, SetCompanyOwner } from '@WebUi/dashboard/store/dashboard.actions';

@State<SuStateModel>({
  name: 'su',
  defaults: SU_STATE_DEFAULTS,
})
@Injectable()
export class SuState {

  @Selector([SuState])
  static allCompanies(state: SuStateModel): CompanyInfo[] {
    return state.allCompanies;
  }

  @Selector([SuState])
  static observedCompanies(state: SuStateModel): UserCompany[] {
    return state.observedCompanies;
  }

  @Selector([SuState.observedCompanies])
  static hasObservedCompanies(observedCompanies: UserCompany[]): boolean {
    return observedCompanies.length > 0;
  }

  @Selector([DashboardState.userCompanies, SuState.observedCompanies])
  static suCompanies(userCompanies: UserCompany[], observedCompanies: UserCompany[]): UserCompany[] {
    return userCompanies.concat(observedCompanies);
  }

  @Selector([SuState.suCompanies])
  static hasSuCompanies(suCompanies: UserCompany[]): boolean {
    return suCompanies.length > 0;
  }

  @Selector([SuState])
  static activeSuCompanyId(state: SuStateModel): ID | null {
    return state.activeSuCompanyId;
  }

  @Selector([SuState.suCompanies, SuState.activeSuCompanyId])
  static activeSuCompany(suCompanies: UserCompany[], activeSuCompanyId: ID | null): UserCompany | null {
    if (!activeSuCompanyId) {
      return null;
    }

    return suCompanies.find((company: UserCompany) => company.id === activeSuCompanyId) ?? null;
  }

  @Selector([SuState.activeSuCompany])
  static activeSuCompanyConnections(activeSuCompany: UserCompany | null): CompanyConnection[] | null {
    return activeSuCompany?.connections ?? null;
  }

  @Selector([SuState.activeSuCompanyConnections])
  static activeSuCompanyHasConnections(activeSuCompanyConnections: CompanyConnection[] | null): boolean | null {
    return activeSuCompanyConnections ? activeSuCompanyConnections.length > 0 : null;
  }

  @Selector([
    SuState.activeSuCompanyConnections,
    DashboardState.externalSystemTypes,
  ])
  static activeSuCompanyAccountingSystemConnection(
    connections: CompanyConnection[] | null,
    externalSystemTypes: ExternalSystemType[],
  ): CompanyConnection | null {
    return connections?.find(isAccountingSystemConnectionPredicate(externalSystemTypes)) ?? null;
  }

  @Selector([SuState])
  static tvSettings(state: SuStateModel): TvSettings {
    return state.tvSettings;
  }

  constructor(private apollo: Apollo) { }

  @Action(SetActiveCompany)
  setActiveCompany(ctx: StateContext<SuStateModel>, payload: SetActiveCompany): void {
    ctx.patchState({
      activeSuCompanyId: payload.companyId,
    });
  }

  @Action(SetActiveSuCompany)
  setActiveSuCompany(ctx: StateContext<SuStateModel>, payload: SetActiveSuCompany): void {
    ctx.patchState({
      activeSuCompanyId: payload.companyId,
    });
  }

  @Action(SetCompanyOwner)
  setCompanyOwner(ctx: StateContext<SuStateModel>, payload: SetCompanyOwner): void {
    ctx.setState(
      patch({
        observedCompanies: updateItem<UserCompany>(
          (company: Readonly<UserCompany>) => company.id === payload.companyUser.companyId,
          patch({
            users: compose(
              updateItem<CompanyUser>(
                (user: Readonly<CompanyUser>) => user.role === CompanyUserRole.OWNER,
                patch({
                  role: CompanyUserRole.REGULAR,
                }),
              ),
              updateItem<CompanyUser>(
                (user: Readonly<CompanyUser>) => user.userId === payload.companyUser.userId,
                patch({
                  role: CompanyUserRole.OWNER,
                }),
              ),
            ),
          }),
        ),
      }),
    );
  }

  @Action(GetAllCompanies)
  getAllCompanies(ctx: StateContext<SuStateModel>): Observable<ApolloQueryResult<GetAllCompaniesAsSuResponse>> {
    return this.apollo.use('companyManager').query<GetAllCompaniesAsSuResponse>({
      query: GET_ALL_COMPANIES_AS_SU,
    })
      .pipe(
        filter((result: ApolloQueryResult<GetAllCompaniesAsSuResponse>) => !!result && !result.loading && !result.partial && !result.error && !result.errors && !!result.data),
        tap((result: ApolloQueryResult<GetAllCompaniesAsSuResponse>) => {
          const companies: CompanyInfo[] = [...result.data.companies];

          companies.sort((a: CompanyInfo, b: CompanyInfo) => {
            try {
              const aId = parseInt(a.id, 10);
              const bId = parseInt(b.id, 10);

              return bId - aId;
            } catch {
              return 0;
            }
          });

          ctx.patchState({
            allCompanies: companies,
          });
        }),
      );
  }

  @Action(GetObservedCompany)
  getObservedCompany(ctx: StateContext<SuStateModel>, payload: GetObservedCompany): Observable<ApolloQueryResult<GetUserCompanyAsSuResponse>> {
    return this.apollo.use('companyManager').query<GetUserCompanyAsSuResponse, GetUserCompanyAsSuResponseVariables>({
      query: GET_USER_COMPANY_AS_SU,
      variables: {
        companyId: payload.companyId,
      },
    })
      .pipe(
        filter((result: ApolloQueryResult<GetUserCompanyAsSuResponse>) => !!result && !result.loading && !result.partial && !result.error && !result.errors && !!result.data),
        tap((result: ApolloQueryResult<GetUserCompanyAsSuResponse>) => {
          ctx.setState(
            patch({
              observedCompanies: iif(
                (observedCompanies: Readonly<UserCompany[]>) => observedCompanies.some((company: Readonly<UserCompany>) => company.id === result.data.superUser.company.id),
                updateItem(
                  (observedCompany: Readonly<UserCompany>) => observedCompany.id === result.data.superUser.company.id,
                  result.data.superUser.company,
                ),
                append([result.data.superUser.company]),
              ),
            }),
          );
        }),
      );
  }

  @Action(AddObservedCompany)
  addObservedCompany(ctx: StateContext<SuStateModel>, payload: AddObservedCompany): void {
    ctx.setState(
      patch({
        observedCompanies: iif(
          (observedCompanies: Readonly<UserCompany[]>) => observedCompanies.every((company: Readonly<UserCompany>) => company.id !== payload.observedCompany.id),
          append([payload.observedCompany]),
        ),
      }),
    );
  }

  @Action(UpdateObservedCompany)
  updateObservedCompany(ctx: StateContext<SuStateModel>, payload: UpdateObservedCompany): void {
    ctx.setState(
      patch({
        observedCompanies: updateItem<UserCompany>(
          (company: Readonly<UserCompany>) => company.id === payload.observedCompany.id,
          payload.observedCompany,
        ),
      }),
    );
  }

  @Action(DeleteObservedCompany)
  deleteObservedCompany(ctx: StateContext<SuStateModel>, payload: DeleteObservedCompany): void {
    ctx.setState(
      patch({
        observedCompanies: removeItem<UserCompany>((company: Readonly<UserCompany>) => company.id === payload.companyId),
      }),
    );
  }

  @Action(ResetObservedCompanies)
  resetObservedCompanies(ctx: StateContext<SuStateModel>): void {
    ctx.patchState({
      observedCompanies: [],
    });
  }

  @Action(StoreTvSettings)
  storeTvSettings(ctx: StateContext<SuStateModel>, payload: StoreTvSettings): void {
    ctx.patchState({
      tvSettings: payload.tvSettings,
    });
  }

  /**
   * React on feedback actions
   * Super user doesnt get notification by signalr so this is the only way to actualize SU state
  */
  @Action(CompanyConnectionCreatedFeedback)
  companyConnectionCreatedFeedback(ctx: StateContext<SuStateModel>, payload: CompanyConnectionCreatedFeedback): void {
    ctx.setState(
      patch({
        observedCompanies: updateItem(
          (observedCompany: Readonly<UserCompany>) => observedCompany.id === payload.companyId,
          patch({
            connections: iif(
              (connections: Readonly<CompanyConnection[]>) => connections.every((connection: Readonly<CompanyConnection>) => connection.id !== payload.companyConnection.id),
              append([payload.companyConnection]),
            ),
          }),
        ),
      }),
    );
  }

}
