import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Heroicon, HeroiconLiteral, HeroiconType, HeroiconTypeLiteral } from '@umw-admin/ui-kit';

@Component({
  selector: 'aeb-icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconInputComponent {

  @Input() icon!: Heroicon | HeroiconLiteral;
  @Input() type!: HeroiconType | HeroiconTypeLiteral;

}
