import { ExternalSystemTypeFeature, ExternalSystemTypeFeatureBookkepingMode, PaymentProvider } from '../external-system';
import { AccountSetting, Department, Project, VatCode } from '../unimicro-platform-data';

export type VippsSettings = PaymentVendorSettings; // No credentials in the model, connection is made through the Vipps API by ledger
export type KlarnaSettings = PaymentVendorSettings & KlarnaCredentialsSettings;
export type StripeSettings = PaymentVendorSettings & StripeCredentialsSettings;
export type NetsSettings = PaymentVendorSettings & NetsCredentialsSettings;
export type PaypalSettings = PaymentVendorSettings & PaypalCredentialsSettings;
export type ClearhausSettings = PaymentVendorSettings & ClearhausCredentialsSettings;
export type SveaSettings = PaymentVendorSettings & SveaCredentialsSettings;
export type QliroSettings = PaymentVendorSettings & QliroCredentialsSettings;

export type PaymentVendorConfiguration = PaymentVendorSettings & PaymentProviderCredentialsSettings;

export type PaymentVendorSettings = PaymentVendorBookkeepingSettings & PaymentVendorAccountsSettings & PaymentVendorDepartmentAndProjectSettings;

export type PaymentVendorBookkeepingSettings = {
  payoutsStartDateUnixTimeSeconds: number | null;
  bookkeepingMode: BookkeepingMode | null;
  invoiceLooseMatchingSettings: InvoiceLooseMatchingSettings | null;
} & DailySummarySettings;

export type PaymentVendorAccountsSettings = {
  paymentVendorAccount: AccountSetting | null;
  paymentVendorFeeAccount: AccountSetting | null;
  paymentVendorInterimAccount: AccountSetting | null;
};

export type PaymentVendorDepartmentAndProjectSettings = {
  department: Department | null;
  project: Project | null;
};

export enum BookkeepingMode {
  TRANSACTION_TO_KNOWN_INVOICE = 0,
  PAYOUT_TRANSACTIONS_SUMMARY = 1,
  TRANSACTION_TO_UNKNOWN_INVOICE = 2,
  PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT = 3,
}

export const BOOKKEEPING_MODES: BookkeepingMode[] = [
  BookkeepingMode.TRANSACTION_TO_KNOWN_INVOICE,
  BookkeepingMode.PAYOUT_TRANSACTIONS_SUMMARY,
  BookkeepingMode.TRANSACTION_TO_UNKNOWN_INVOICE,
  BookkeepingMode.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT,
];

export const BOOKKEEPING_MODE_TO_EXTERNAL_SYSTEM_TYPE_FEATURE_MAP: Record<BookkeepingMode, ExternalSystemTypeFeatureBookkepingMode> = {
  [BookkeepingMode.TRANSACTION_TO_KNOWN_INVOICE]: ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING,
  [BookkeepingMode.PAYOUT_TRANSACTIONS_SUMMARY]: ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING,
  [BookkeepingMode.TRANSACTION_TO_UNKNOWN_INVOICE]: ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING,
  [BookkeepingMode.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT]: ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING,
};

export const EXTERNAL_SYSTEM_TYPE_FEATURE_TO_BOOKKEEPING_MODE_MAP: Record<ExternalSystemTypeFeatureBookkepingMode, BookkeepingMode> = {
  [ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING]: BookkeepingMode.TRANSACTION_TO_KNOWN_INVOICE,
  [ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING]: BookkeepingMode.PAYOUT_TRANSACTIONS_SUMMARY,
  [ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING]: BookkeepingMode.TRANSACTION_TO_UNKNOWN_INVOICE,
  [ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING]: BookkeepingMode.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT,
};

export type DailySummarySettings = {
  bookkeepAsDraft: boolean;
  standardVatType: VatCode | null;
  incomeAccount: AccountSetting | null;
};

export interface InvoiceLooseMatchingSettings {
  referenceFields: InvoiceLooseMatchingReferenceField | null;
  matchTerm: string | null;
}

export enum MatchingCriteria {
  REFERENCE_FIELD = 0,
  LOOSE_MATCHING = 1,
}

export enum InvoiceLooseMatchingReferenceField {
  EXTERNAL_REFERENCE = 0,
  YOUR_REFERENCE = 1,
  REQUISITION = 2,
  PAYMENT_TERMS = 4,
  FREE_TEXT = 8,
  COMMENT = 16,
}

export const INVOICE_LOOSE_MATCHING_REFERENCE_FIELDS: InvoiceLooseMatchingReferenceField[] = [
  InvoiceLooseMatchingReferenceField.EXTERNAL_REFERENCE,
  InvoiceLooseMatchingReferenceField.YOUR_REFERENCE,
  InvoiceLooseMatchingReferenceField.REQUISITION,
  InvoiceLooseMatchingReferenceField.PAYMENT_TERMS,
  InvoiceLooseMatchingReferenceField.FREE_TEXT,
  InvoiceLooseMatchingReferenceField.COMMENT,
];

// Vipps, Vippsnummer do not have credentials settings
// eslint-disable-next-line @typescript-eslint/ban-types
export type VippsCredentialsSettings = {};
export type KlarnaCredentialsSettings = KlarnaCredentials & WithTestEnvironment;
export type StripeCredentialsSettings = StripeCredentials;
export type NetsCredentialsSettings = NetsCredentials & WithTestEnvironment;
export type PaypalCredentialsSettings = PaypalCredentials & WithTestEnvironment;
export type ClearhausCredentialsSettings = ClearhausCredentials & WithTestEnvironment;
export type SveaCredentialsSettings = SveaCredentials & WithTestEnvironment;
export type QliroCredentialsSettings = QliroCredentials;

export type PaymentProviderCredentialsSettings = ClearhausCredentialsSettings | KlarnaCredentialsSettings | NetsCredentialsSettings | PaypalCredentialsSettings | StripeCredentialsSettings | SveaCredentialsSettings | VippsCredentialsSettings;

export type PaymentProviderCredentials = ClearhausCredentials | KlarnaCredentials | NetsCredentials | PaypalCredentials | StripeCredentials | SveaCredentials;

export type PaymentProviderCredentialsSettingsHarmony<T extends PaymentProvider> =
  T extends PaymentProvider.KLARNA ? KlarnaCredentialsSettings :
  T extends PaymentProvider.STRIPE ? StripeCredentialsSettings :
  T extends PaymentProvider.NETS ? NetsCredentialsSettings :
  T extends PaymentProvider.PAYPAL ? PaypalCredentialsSettings :
  T extends PaymentProvider.CLEARHAUS ? ClearhausCredentialsSettings :
  T extends PaymentProvider.SVEA ? SveaCredentialsSettings :
  T extends PaymentProvider.QLIRO ? QliroCredentialsSettings :
  null;

export interface WithTestEnvironment {
  useTestEnvironment: boolean;
}

export type KlarnaCredentials = {
  username: string;
  password: string;
};

export type StripeCredentials = {
  apiSecretKey: string;
};

export type NetsCredentials = {
  privateApiKey: string;
};

export type PaypalCredentials = {
  clientId: string;
  clientSecret: string;
};

export type ClearhausCredentials = {
  clientId: string;
  clientSecret: string;
};

export type SveaCredentials = {
  merchantId: string;
  secretWord: string;
};

export type QliroCredentials = {
  apiSecretKey: string;
};
